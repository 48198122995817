var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1 mt-4"},[_c('v-card-title',[_vm._v(" Account Profit Breakdown ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.searchTable,"options":_vm.options,"loading":_vm.loading,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.processed_at === null ? 'error' : 'success',"label":"","outlined":""}},[_vm._v(" "+_vm._s(item.processed_at === null ? 'PENDING' : 'PROCESSED')+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.amount))+" ")]}},{key:"item.service_charge",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.service_charge))+" ")]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.total_amount))+" ")]}}],null,true)}),(_vm.responseMessage.length > 0)?_c('snackbar',{attrs:{"show":_vm.responseMessage.length > 0,"text":_vm.responseMessage,"color":_vm.responseMessageStatus},on:{"close":function($event){_vm.responseMessage=''}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }