<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7" v-if="!usernameWasUpdated">
      <v-card-title>
        Change Username
      </v-card-title>

      <v-card-text>
        <v-alert
          color="warning"
          text
        >
          <p class="font-weight-semibold mb-1">
            Ensure that these requirements are met
          </p>
          <p class="text-sm mb-0">
            Minimum 8 characters long
          </p>
        </v-alert>

        <!-- form -->
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="newUsername"
                outlined
                dense
                maxlength="15"
                label="New Username"
                :error-messages="formErrorFields.usernameErrorMessage"
                @keydown="formError().remove('username', formErrorFields)"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="success"
                :loading="loading"
                @click="showConfirmUpdateUsernameDialog=true"
              >
                Update Username
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title>
        Change Password
      </v-card-title>

      <v-card-text>
        <v-alert
          color="warning"
          text
        >
          <p class="font-weight-semibold mb-1">
            Ensure that these requirements are met
          </p>
          <p class="text-sm mb-0">
            Minimum 8 characters long, lowercase, uppercase, symbol and numbers
          </p>
        </v-alert>

        <!-- form -->
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newPassword"
                outlined
                dense
                label="New Password"
                :error-messages="formErrorFields.passwordErrorMessage"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
                @keydown="formError().remove('password', formErrorFields)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="confirmNewPassword"
                outlined
                dense
                label="Confirm Password"
                :type="isPasswordConfirmVisible ? 'text' : 'password'"
                :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="success"
                :loading="loading"
                @click="showConfirmUpdatePasswordDialog=true"
              >
                Update Password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- two step verification -->
    <v-card class="mb-7">
      <v-card-title class="flex-nowrap">
        <v-icon class="text--primary me-3">
          {{ icons.mdiKeyOutline }}
        </v-icon>
        <span class="text-break">Two-factor authentication</span>
      </v-card-title>

      <v-card-text class="two-factor-auth text-center mx-auto">
        <v-btn
          class="mb-4"
          color="success"
          :loading="loading2FA"
          :title="TFA === 1 ? 'Disable two factor authentication' : 'Enable two factor authentication'"
          @click="showConfirmEnableOrDisable2FADialog=true"
        >
          <v-icon>{{ TFA === 1 ? icons.mdiLockOffOutline : icons.mdiLockOpenOutline }}</v-icon>
        </v-btn>
        <p
          v-if="TFA === 0"
          class="text-base text--primary font-weight-semibold"
        >
          Two factor authentication is not enabled yet.
        </p>
        <p
          v-else
          class="text-base text--primary font-weight-semibold"
        >
          Two factor authentication is enabled.
        </p>
        <div v-if="showGoogle2FAQRCode">
          Scan the following QR code using your phone's authenticator application.
          <div
            class="mt-4"
            v-html="google2FAQRCode"
          ></div>
        </div>
        <p class="text-sm text--primary">
          Two-factor authentication adds an additional layer of
          security to your account by requiring more than just a
          password to log in.
        </p>
      </v-card-text>
    </v-card>

    <!-- recent divices -->
    <v-card>
      <v-card-title class="flex-nowrap">
        <v-icon class="text--primary me-3">
          {{ icons.mdiGoogleChrome }}
        </v-icon>
        <span class="text-break">Browser Sessions</span>
      </v-card-title>

      <v-card-text class="two-factor-auth text-center mx-auto">
        <v-btn
          color="success"
          class="primary mb-4"
          @click.prevent="showConfirmLogoutSessionDialog=true"
        >
          Logout other browser sessions
        </v-btn>
        <p class="text-sm text--primary">
          If nescessary, you may logout of all of your other browser sessions accross all of your devices.
          If you feel your account has been compromise, you should also update your password.
        </p>
      </v-card-text>
    </v-card>

    <!-- dialog -->
    <v-dialog
      v-model="istwoFactorDialogOpen"
      max-width="650px"
    >
      <v-card class="two-factor-dialog pa-sm-10">
        <v-card-title class="text-sm-h5 justify-center">
          Enable One Time Password
        </v-card-title>
        <v-card-text>
          <h4 class="text-lg font-weight-medium mb-2">
            Verify Your Mobile Number for SMS
          </h4>
          <p>Enter your mobile phone number with country code and we will send you a verification code.</p>

          <v-form>
            <v-text-field
              v-model="smsVerificationNumber"
              outlined
              dense
              placeholder="Mobile number with country code"
              label="Mobile number with country code"
            ></v-text-field>

            <div class="d-flex align-center">
              <v-btn
                color="secondary"
                outlined
                class="me-3 ms-auto"
                @click="istwoFactorDialogOpen = !istwoFactorDialogOpen"
              >
                Cancel
              </v-btn>
              <v-btn
                color="success"
                @click="istwoFactorDialogOpen = !istwoFactorDialogOpen"
              >
                send
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>

    <confirm-password
      :show="showConfirmEnableOrDisable2FADialog"
      @cancel="showConfirmEnableOrDisable2FADialog=false"
      @execute="enableOrDisable2FA"
    >
    </confirm-password>

    <confirm-password
      :show="showConfirmLogoutSessionDialog"
      @cancel="showConfirmLogoutSessionDialog=false"
      @execute="logoutOtherBrowserSession"
    >
    </confirm-password>

    <confirm-password
      :show="showConfirmUpdatePasswordDialog"
      @cancel="showConfirmUpdatePasswordDialog=false"
      @execute="updatePassword"
    >
    </confirm-password>

    <confirm-password
      :show="showConfirmUpdateUsernameDialog"
      @cancel="showConfirmUpdateUsernameDialog=false"
      @execute="updateUsername"
    >
    </confirm-password>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiLockOffOutline,
  mdiLockOpenOutline,
  mdiGoogleChrome,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import get from '@/composables/get'
import crud from '@/composables/crud'
import formError from '@/composables/formErrorHandler/formError'
import snackbar from '@/components/SnackBar.vue'
import ConfirmPassword from '@/components/ConfirmPassword.vue'
import fnx from '@/functions/fn'

export default {
  components: { snackbar, ConfirmPassword },

  props: ['userData'],

  setup(props, { emit }) {
    const TFA = ref(0)
    const loading2FA = ref(false)
    const loading = ref(false)
    const loadingLogoutBrowserSession = ref(false)
    const google2FAQRCode = ref(false)
    const showGoogle2FAQRCode = ref(false)
    const showConfirmEnableOrDisable2FADialog = ref(false)
    const showConfirmUpdateUsernameDialog = ref(false)
    const showConfirmUpdatePasswordDialog = ref(false)
    const showConfirmLogoutSessionDialog = ref(false)
    const istwoFactorDialogOpen = ref(false)
    const newUsername = ref('')
    const newPassword = ref('')
    const confirmNewPassword = ref('')
    const smsVerificationNumber = ref('+1(968) 819-2547')
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)
    const isCurrentPasswordVisible = ref(false)
    const formErrorFields = ref({
      passwordErrorMessage: '',
    })
    const usernameWasUpdated = ref(true)

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const updatePassword = async () => {
      const formData = {
        password: newPassword.value,
        password_confirmation: confirmNewPassword.value,
      }

      const uri = `${process.env.VUE_APP_URI}/api/member/account/update-password`
      await update(uri, loading, formData, formErrorFields)
      if (responseMessageStatus.value === 'success') {
        newPassword.value = ''
        confirmNewPassword.value = ''
      }
    }

    const updateUsername = async () => {
      const formData = {
        username: newUsername.value,
      }

      const uri = `${process.env.VUE_APP_URI}/api/member/account/update-username`
      await update(uri, loading, formData, formErrorFields)
      if (responseMessageStatus.value === 'success') {
        newUsername.value = ''
        usernameWasUpdated.value = true
        emit('updateUserInfo')
      }
    }

    // check 2FA
    const checkFor2FA = async () => {
      await get(`${process.env.VUE_APP_URI}/api/member/account/check-two-factor-authentication`, TFA, loading2FA)
    }

    checkFor2FA()

    // enable 2FA
    const enableOrDisable2FA = async () => {
      await update(`${process.env.VUE_APP_URI}/api/member/account/${TFA.value === 1 ? 'disable-2FA' : 'enable-2FA'}`, loading2FA, {}, {}, google2FAQRCode)
      if (responseMessageStatus.value === 'success') {
        await checkFor2FA()
        if (TFA.value === 1) {
          google2FAQRCode.value = fnx.base64_decode(google2FAQRCode.value.qrcode)
          showGoogle2FAQRCode.value = true
        } else {
          showGoogle2FAQRCode.value = false
        }
      }

      setTimeout(() => {
        showGoogle2FAQRCode.value = false
      }, 180000)
    }

    // logout all sessions
    const logoutOtherBrowserSession = async () => {
      await store(`${process.env.VUE_APP_URI}/api/member/account/logout-all-sessions`, loadingLogoutBrowserSession)
    }

    const validateIfusernameIsUpdated = () => {
      console.log(props.userData)
      if(props.userData !== undefined) {
        if(props.userData.old_username != null) {
          usernameWasUpdated.value = true
        } else {
          usernameWasUpdated.value = false
        }
      } else {
        usernameWasUpdated.value = false
      }
    }

    validateIfusernameIsUpdated()

    return {
      TFA,
      loadingLogoutBrowserSession,
      loading2FA,
      loading,
      google2FAQRCode,
      istwoFactorDialogOpen,
      newUsername,
      newPassword,
      confirmNewPassword,
      smsVerificationNumber,
      formError,
      formErrorFields,
      isPasswordVisible,
      isPasswordConfirmVisible,
      isCurrentPasswordVisible,
      icons: {
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiLockOffOutline,
        mdiLockOpenOutline,
        mdiGoogleChrome,
      },
      responseMessage,
      responseMessageStatus,
      updatePassword,
      updateUsername,
      showGoogle2FAQRCode,
      showConfirmEnableOrDisable2FADialog,
      showConfirmLogoutSessionDialog,
      showConfirmUpdatePasswordDialog,
      showConfirmUpdateUsernameDialog,
      enableOrDisable2FA,
      logoutOtherBrowserSession,
      usernameWasUpdated,
    }
  },
}
</script>
