<template>
  <v-card
    class="elevation-1"
    :loading="loading"
  >
    <v-card-title class="py-9">
      Assignment Form
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col
            cols="12"
            :lg="account==='Other Account' ? 6 : 12"
            class="mt-0 pt-0 mb-2"
          >
            <v-autocomplete
              v-model="account"
              outlined
              dense
              clearable
              label="Select Account"
              item-text="text"
              item-value="text"
              :items="accounts"
              :hide-details="formErrorFields.accountErrorMessage.length === 0"
              :error-messages="formErrorFields.accountErrorMessage"
              @change="accountWasChanged"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            :lg="6"
            class="mt-0 pt-0 mb-2"
            v-if="account==='Other Account'"
          >
            <v-text-field
              v-model="memberId"
              label="Member ID"
              outlined
              dense
              :hide-details="formErrorFields.memberIdErrorMessage.length===0"
              :error-messages="formErrorFields.memberIdErrorMessage"
              @keydown="formError().remove('memberId', formErrorFields)"
              @blur="getMember"
              @keypress.enter="getMember"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="mt-0 pt-0 mb-2"
            v-if="member!==null"
          >
            <v-text-field
              v-model="member.name"
              label="Member Name"
              hide-details
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-0 pt-0">
          <v-col
            cols="12"
            lg="8"
            class="pt-0 mt-0"
          >
            <!-- Product -->
            <v-autocomplete
              v-model="product"
              outlined
              dense
              clearable
              label="Product"
              item-text="product_name"
              item-value="id"
              :items="products"
              :hide-details="formErrorFields.productErrorMessage.length === 0"
              :error-messages="formErrorFields.productErrorMessage"
              @change="formError().remove('product', formErrorFields)"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            class="pt-0 mt-0"
          >
            <!-- Quantity -->
            <v-text-field
              v-model="quantity"
              label="Quantity"
              outlined
              dense
              type="number"
              placeholder="0.00"
              class="mb-2"
              :hide-details="formErrorFields.quantityErrorMessage.length === 0"
              :error-messages="formErrorFields.quantityErrorMessage"
              @keydown="formError().remove('quantity', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0 mb-3">
          <v-col class="mt-0 pt-0" cols="12">
            <v-btn
              color="success"
              :loading="loading"
              :disabled="memberId===''||product===''||quantity===''"
              @click="addItem"
            >
              Add
            </v-btn>
          </v-col>
        </v-row>

        <v-simple-table class="mb-3" dense>
          <thead>
            <tr>
              <th class="pb-1 pt-1 text-left">Member ID</th>
              <th class="pb-1 pt-1 text-left">Product</th>
              <th class="pb-1 pt-1 text-right">Qty</th>
              <th class="pb-1 pt-1 text-right">Total</th>
              <th class="pb-1 pt-1 text-center">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="index"
            >
              <td class="pb-1 pt-1 text-left">{{ item.member_id }}</td>
              <td class="pb-1 pt-1 text-left">{{ item.product_name }}</td>
              <td class="pb-1 pt-1 text-right">{{ item.quantity }}</td>
              <td class="pb-1 pt-1 text-right">{{ item.total }}</td>
              <td class="pb-1 pt-1 text-center">
                <v-btn
                  x-small
                  icon
                  color="error"
                  @click="deleteItem(index)"
                >
                  <v-icon>{{ icon.delete }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-btn
          color="success"
          :loading="loading"
          :disabled="items.length===0"
          @click="submit"
        >
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="items.length===0"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import Cookies from 'universal-cookie'
import { mdiTrashCanOutline } from '@mdi/js'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import stateState from '../../../store'

export default {

  components: {
    snackbar,
  },
  props: ['products'],
  setup(props, { emit }) {
    const loading = ref(false)
    const message = ref('')
    const products = ref([])
    const product = ref('')
    const account = ref(null)
    const accounts = ref([])
    const quantity = ref('')
    const memberId = ref('')
    const formErrorFields = ref({
      productErrorMessage: '',
      accountErrorMessage: '',
      quantityErrorMessage: '',
      memberIdErrorMessage: '',
    })
    const items = ref([])
    const member = ref(null)
    const error = ref(null)

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const parseAccount = () => {
      const user = stateState.state.user.info
      
      accounts.value = []

      for(const row of user.member_account) {
        accounts.value.push({value: row.id, text: row.registration_code})
      }

      accounts.value.push({value: 'Other Account', text: 'Other Account'})
      account.value = user.member_account[0].id
    }

    parseAccount()

    const reset = () => {
      product.value = ''
      account.value = null
      quantity.value = ''
      memberId.value = ''
    }

    const submit = async () => {
      const formData = {
        items: items.value,
      }

      const uri = `${process.env.VUE_APP_URI}/api/member/maintenance/assignment`
      await store(uri, loading, formData, formErrorFields)

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        items.value = []
        emit('updateUniqueID')
      }
    }

    const accountWasChanged = val => {
      if(val !== 'Other Account') {
        memberId.value = account.value
        member.value = null
      } else {
        memberId.value = ''
        member.value = null
      }

      formError().remove('account', formErrorFields.value)
    }

    const getMember = async () => {
      if(memberId.value !== '') {
        await get(`${process.env.VUE_APP_URI}/api/member/maintenance/${memberId.value}/get-member-info`, member, null, error)
        if(error.value !== null) {
          formErrorFields.value.memberIdErrorMessage = error.value.data.errors.memberId
          error.value = null
          member.value = null
        } else {
          error.value = null
        }
      } else {
        member.value = null
      }
    }

    const addItem = () => {
      if(account.value === 'Other Account' && member.value === null) return false

      const itemIndex = items.value.findIndex(val => val.product_id === product.value && val.member_id === memberId.value)

      if(itemIndex >= 0) {
        items.value[itemIndex].quantity = parseInt(items.value[itemIndex].quantity) + parseInt(quantity.value)
        items.value[itemIndex].total = parseInt(items.value[itemIndex].point_value) * parseInt(quantity.value)
      } else {
        const productIndex = props.products.findIndex(val => val.id === product.value)
        const productName = props.products[productIndex].product_name
        const pointValue = props.products[productIndex].point_value
        items.value.push({
          'member_id': memberId.value,
          'product_id': product.value,
          'product_name': productName,
          'point_value': pointValue,
          'quantity': parseInt(quantity.value),
          'total': parseInt(quantity.value) * pointValue,
        })
      }

      product.value = ''
      quantity.value = ''
    }

    const deleteItem = index => {
      items.value.splice(index, 1);
    }
    
    return {
      product,
      account,
      accounts,
      quantity,
      memberId,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      accountWasChanged,
      items,
      member,

      reset,
      submit,
      addItem,
      deleteItem,
      getMember,

      icon: {
        delete: mdiTrashCanOutline,
      }
    }
  },
}
</script>
