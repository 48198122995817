<template>
  <v-card class="elevation-1">
    <v-card-title>
      Maintenance History
    </v-card-title>
    <v-card-text class="mb-0 pb-0">
      <v-row>
        <v-col
          cols="12"
          md="4"
          lg="4"
        >
          <v-select
            v-model="account"
            :items="accounts"
            :item-text="'text'"
            :item-key="'id'"
            label="Account"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="4"
          offset-md="4"
          offset-lg="4"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.point_value`]="{ item }">
        {{ Intl.NumberFormat('en-US').format(item.point_value) }}
      </template>
      <template #[`item.total_point_value`]="{ item }">
        {{ Intl.NumberFormat('en-US').format(item.quantity * item.point_value) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import Cookies from 'universal-cookie'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import store from '../../store'

export default {
  setup(props) {
    const search = ref('')
    const headers = ref([
       { text: 'Date', align: 'start', value: 'created_at_formatted' },
      { text: 'Product Name', align: 'start', value: 'product_name' },
      { text: 'Point Value', align: 'end', value: 'point_value' },
      { text: 'Quantity', align: 'end', value: 'quantity' },
      { text: 'Total PV', align: 'end', value: 'total_point_value' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const account = ref('')
    const accounts = ref([])

    const listUri = `${process.env.VUE_APP_URI}/api/member/maintenance/history`

    const parseAccount = () => {
      const user = store.state.user.info
      
      for(const row of user.member_account) {
        accounts.value.push({value: row.id, text: row.registration_code});
      }

      account.value = user.member_account[0].id
      getData(listUri, items, totalItems, loading, options, search, account.value)
    }

    parseAccount()

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search, account.value)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search, account.value)
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      accounts,
      account,
    }
  },

  components: {
    snackbar,
  },
}
</script>
