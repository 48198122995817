const func = {
  ucwords(value) {
    let str = ''
    if (value !== null && value !== '' && value !== undefined && !Number.isInteger(value)) {
      str = value.toLowerCase()

      const strArray = str.split(' ')
      let i = 0

      str = ''

      strArray.forEach(val => {
        str += ((i > 0) ? ` ${val.charAt(0).toUpperCase()}${val.slice(1)}` : val.charAt(0).toUpperCase() + val.slice(1))
        i += 1
      })
    }

    return str
  },

  format_number(value, digit) {
    const num = parseFloat(value)

    if (!Number.isNaN(parseFloat(num))) {
      const number = num.toFixed(digit)

      return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return 0
  },

  string_number(num) {
    return num.toString().replace(',', '')
  },

  currency_format(number, sym = null, loc = null) {
    const locale = loc != null ? loc : 'en-PH'
    const symbol = sym != null ? sym : 'PHP'

    const formatteNumber = number.toLocaleString(locale, {
      style: 'currency',
      currency: symbol,
    })

    return formatteNumber
  },

  base64_encode(string) {
    const encodedString = typeof (string) === 'object' ? window.btoa(JSON.stringify(string)) : window.btoa(string)

    return encodedString
  },

  base64_decode(string) {
    let isObject = false
    const str = window.atob(string)

    try {
      JSON.parse(str)
      isObject = true
    } catch (e) {
      if (e instanceof SyntaxError) {
        isObject = false
      }
    }

    const decodeString = isObject ? JSON.parse(str) : str

    return decodeString
  },
  errorMessage(error) {
    const { status } = error.response
    if (status === 400) {
      return error.response.data.message
    }

    return error.response.statusText
  },

  date() {
    const d = new Date()

    return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`
  },

  formatDate(date) {
    const d = new Date(date)

    return `${(d.getMonth() + 1).padStart(2, '0')}/${d.getUTCDate()}/${d.getUTCFullYear()}`
  },

  getYearDifference(d) {
    if (d == null) return 0

    const currentDate = new Date()
    const dt = new Date(d)
    const diff = currentDate.getFullYear() - dt.getFullYear()

    return diff
  },

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object') {
      Object.keys(data).forEach(key => {
        func.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
      })
    } else {
      const value = data == null ? '' : data

      formData.append(parentKey, value)
    }
  },

  computeHours(xfrom, xto) {
    const from = new Date(xfrom)
    const to = new Date(xto)
    const days = (to - from) / (1000 * 60 * 60 * 24) + 1
    const total = days * 8

    return total.toFixed(2)
  },
}

export default func
