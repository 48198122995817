<template>
  <v-card class="elevation-1">
    <v-card-title>
      Inventory
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      hide-default-footer
      dense
    >
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="item.processed_at === null ? 'error' : 'success'"
          label
          outlined
        >
          {{ item.processed_at === null ? 'PENDING' : 'PROCESSED' }}
        </v-chip>
      </template>
      <template #[`item.point_value`]="{ item }">
        {{ Intl.NumberFormat('en-US').format(item.point_value) }}
      </template>
      <template #[`item.total_point_value`]="{ item }">
        {{ Intl.NumberFormat('en-US').format(item.quantity * item.point_value) }}
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch, watchEffect } from '@vue/composition-api'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'

export default {

  components: {
    snackbar,
  },
  props: ['uniqueID', 'products'],
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Product Code', align: 'start', value: 'product_code' },
      { text: 'Product Name', align: 'start', value: 'product_name' },
      { text: 'Point Value', align: 'end', value: 'point_value' },
      { text: 'Quantity', align: 'end', value: 'quantity' },
      { text: 'Total Point Value', align: 'end', value: 'total_point_value' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)

    const { responseMessageStatus, responseMessage } = crud()

    watch(() => props.uniqueID, () => {
      items.value = props.products
      totalItems.value = props.products.length
    })

    watch(() => props.products, val => {
      items.value = val
      totalItems.value = val.length
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      snackbar,
      responseMessageStatus,
      responseMessage,
    }
  },
}
</script>
