<template>
  <v-card class="elevation-1 mt-4" >
    <v-card-title>
      Account Profit Breakdown
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :loading="loading"
      dense
    >
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="item.processed_at === null ? 'error' : 'success'"
          label
          outlined
        >
          {{ item.processed_at === null ? 'PENDING' : 'PROCESSED' }}
        </v-chip>
      </template>
      <template #[`item.amount`]="{ item }">
        {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.amount) }}
      </template>
      <template #[`item.service_charge`]="{ item }">
        {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.service_charge) }}
      </template>
      <template #[`item.total_amount`]="{ item }">
        {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.total_amount) }}
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'

export default {

  components: {
    snackbar,
  },
  props: ['uniqueID'],
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'ID Number', align: 'start', value: 'registration_code' },
      { text: 'Referral', align: 'end', value: 'referral_bonus' },
      { text: 'Projected Unilevel Bonus', align: 'end', value: 'projected_unilevel' },
      { text: 'Unilevel', align: 'end', value: 'unilevel_bonus' },
      { text: 'Retail Profit', align: 'end', value: 'retail_profit' },
      { text: 'Cash Back', align: 'end', value: 'cash_back' },
      { text: 'Inderect', align: 'end', value: 'inderect' },
      { text: 'Stair Step', align: 'end', value: 'stair_step' },
      { text: 'Global Pool', align: 'end', value: 'global_pool' },
      { text: 'Profit Share', align: 'end', value: 'profit_share' },
      { text: 'CPP Bonus', align: 'end', value: 'cpp_bonus' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)

    const { responseMessageStatus, responseMessage } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/member/dashboard/account-profit-breakdown`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(() => props.uniqueID, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      snackbar,
      responseMessageStatus,
      responseMessage,
    }
  },
}
</script>
