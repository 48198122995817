<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
      lg="4"
      md="4"
      xl="4"
    >
      <form-view
        :data="fomrData"
        :products="products"
        @updateUniqueID="updateUniqueID"
      ></form-view>
    </v-col>
    <v-col
      cols="12"
      lg="8"
      md="8"
      xl="8"
    >
        <inventory
          :uniqueID="uniqueId"
          :products="products"
        ></inventory>
        <history
          :uniqueID="uniqueId"
          @passData="passData"
        ></history>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import FormView from './Components/form.vue'
import Inventory from './Components/available.vue'
import History from './Components/assignment-history.vue'
import get from '@/composables/get'

export default {
  metaInfo: {
    title: 'Deductions',
  },
  components: {
    History,
    FormView,
    Inventory,
  },

  setup() {
    const fomrData = ref({})
    const uniqueId = ref('')
    const products = ref([])

    const passData = d => {
      fomrData.value = d
    }

    const getProducts = async () => {
      await get(`${process.env.VUE_APP_URI}/api/member/maintenance/products`, products, null)
    }

    getProducts()

    const updateUniqueID = () => {
      uniqueId.value = Math.random()
      getProducts()
    }

    return {
      fomrData,
      passData,
      updateUniqueID,
      uniqueId,
      products,
    }
  },
}
</script>
