<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
      lg="3"
      md="3"
      xl="3"
    >
      <form-view
        :data="fomrData"
        @updateUniqueID="updateUniqueID"
      ></form-view>
    </v-col>
    <v-col
      cols="12"
      lg="9"
      md="9"
      xl="9"
    >
      <list-view
        :unique-i-d="uniqueId"
        @passData="passData"
      ></list-view>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import FormView from '@/views/Wallet/form.vue'
import ListView from '@/views/Wallet/list.vue'

export default {
  metaInfo: {
    title: 'Deductions',
  },

  setup() {
    const fomrData = ref({})
    const uniqueId = ref('')

    const passData = d => {
      fomrData.value = d
    }

    const updateUniqueID = () => {
      uniqueId.value = Math.random()
    }

    return {
      fomrData,
      passData,
      updateUniqueID,
      uniqueId,
    }
  },

  components: {
    ListView,
    FormView,
  },
}
</script>
