import axios from 'axios'
import Cookies from 'universal-cookie'

const requests = () => {
  const cookie = new Cookies()
  const accessToken = cookie.get('access_token')

  axios.defaults.withCredentials = true
  axios.interceptors.request.use(conf => {
    // Do something before request is sent
    const config = conf
    config.headers.Authorization = `Bearer ${accessToken}`
    config.headers.accept = 'Application/json'

    return config
  }, error => Promise.reject(error))

  axios.interceptors.response.use(response => response, error => {

    return Promise.reject(error)
  })
}

export default requests
