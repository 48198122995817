import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'universal-cookie'
import interceptors from '@/functions/request'
import store from '../store'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/genealogy/unilevel/:downlineId?',
    name: 'genealogy-unilevel',
    component: () => import('@/views/Genealogy/UnilevelStructure.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/genealogy/cpp',
    name: 'genealogy-cpp',
    component: () => import('@/views/Genealogy/Cpp.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/earnings/cash-backs',
    name: 'cash-backs',
    component: () => import('@/views/Earnings/cashback.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/earnings/referral',
    name: 'earnings-referral',
    component: () => import('@/views/Earnings/referral-bonus.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/earnings/unilevel',
    name: 'earnings-unilevel',
    component: () => import('@/views/Earnings/unilevel-bonus.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/earnings/personal-rebates',
    name: 'earnings-personal-rebates',
    component: () => import('@/views/Earnings/personal-rebates.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/earnings/cpp',
    name: 'earnings-cpp',
    component: () => import('@/views/Earnings/cpp-bonus.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/Wallet/index.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/maintenance-assignment',
    name: 'maintenance-assignment',
    component: () => import('@/views/Maintenance/assignment.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/maintenance-history',
    name: 'maintenance-history',
    component: () => import('@/views/Maintenance/history.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/User/Index.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/add-account',
    name: 'add-account',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/upgrade-account',
    name: 'upgrade-account',
    component: () => import('@/views/UpgradeAccount.vue'),
    meta: {
      layout: 'content',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login-v2.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/register/:sponsor?',
    name: 'auth-register',
    component: () => import('@/views/authentication/Register-v2.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword-v2.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/otp',
    name: 'otp',
    component: () => import('@/views/authentication/Otp.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {

  const cookies = new Cookies()
  const accessToken = cookies.get('access_token')

	const isLogged = accessToken !== undefined ? true : false
  interceptors()
  
	if (to.meta.requiresAuth && isLogged) {
		// 1. If the name is not set, it means we are navigating to the first page
		// and we are logged in, so we should check user information from the server
		try {
		  // Do api request call to retreive user profile.
		  // Note that the api is provided with json-server
      interceptors()
      const {data: user} = await axios.get(`${process.env.VUE_APP_URI}/api/member/account/is-logged`)
		  await store.dispatch('setInfo', user)
      
		  // store.commit('info', user)
		  // notyf.success(`Welcome back, ${user.firstname}`)
		} catch (err) {
      // delete stored token if it fails
      // interceptors()
      // store.dispatch('logoutUser')

      await cookies.remove('access_token', { 
        path    : '/',
        sameSite: true
      });
      
      await cookies.remove('expires_in', { 
        path    : '/',
        sameSite: true
      });
			//   notyf.error('Your session is invalid')
  
			if (to.meta.requiresAuth) {
				// redirect the user somewhere
				next ({
					// Will follow the redirection set in /@src/pages/auth/index.vue
					name: 'auth-login',
					// save the location we were at to come back later
					// query: { redirect: to.fullPath },
          replace: true,
				})
		  }
		}
  } else if (to.meta.requiresAuth && !isLogged) {
    next ({
      // Will follow the redirection set in /@src/pages/auth/index.vue
      name: 'auth-login',
      // save the location we were at to come back later
      //   query: { redirect: to.fullPath },
      replace: true,
    })
  } else if (!to.meta.requiresAuth && isLogged) {
    interceptors()
    next ({
      // Will follow the redirection set in /@src/pages/auth/index.vue
      path: '/dashboard',
			replace: true,
    })
  } 

  interceptors()
  next()
})

export default router
