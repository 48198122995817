import Cookies from 'universal-cookie'
import axios from 'axios'
import { ref } from '@vue/composition-api'
import fn from '@/functions/fn'
import formError from '../formErrorHandler/formError'
import store from '@/store'

const signin = () => {
  const errorData = ref('')

  const go = async (username, password, formErrorFields, router) => {
    const uname = username
    const pwd = password
    const formData = {
      username: uname.value,
      password: pwd.value,
    }

    try {
      const response = await axios.post(`${process.env.VUE_APP_URI}/api/auth/member/login`, formData)
      let data = response
      data = data.data

      const cookies = new Cookies()
      await cookies.set('access_token', data.access_token, {
        path: '/',
        sameSite: true,
      })

      await cookies.set('expires_in', data.expires_in, {
        path: '/',
        sameSite: true,
      })
      
      store.commit('info', data.user)
      // router.replace({ path: '/dashboard' })
      window.location.href = '/'
    } catch (error) {
      console.log(error)
      if (error.response.status !== undefined) {
        if (error.response.status === 422) {
          formError().handle(error, formErrorFields)
          pwd.value = ''
        } else {
          errorData.value = error.response
        }
      }
    }
  }

  return { errorData, go }
}

export default signin
