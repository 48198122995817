<template>
  <v-card class="elevation-1">
    <v-card-title>
      Unilevel Structure
    </v-card-title>
    <v-card-text class="mb-0 pb-0">
      <v-row>
        <v-col
          cols="12"
          md="4"
          lg="4"
        >
          <v-select
            v-model="account"
            :items="accounts"
            :item-text="'text'"
            :item-key="'id'"
            label="Account"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="2"
          lg="2"
        >
          <v-select
            v-model="level"
            :items="levels"
            :item-text="'text'"
            :item-key="'id'"
            label="Level"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :loading="loading"
      dense
    >
      <template #[`item.img`]="{ item }">
        <v-avatar
          class="my-2"
          color="orange">
          <img
            color="orange"
            :src="item.image == null ? require(`@/assets/images/avatars/${item.entry_type.replace(' ', '_').toLowerCase()}.png`) : `${process.env.VUE_APP_URI}` + '/image/' + fnx.base64_encode(item.image)" />
        </v-avatar>
      </template>
      <template #[`item.action`]="{ item }">
        <v-btn
          v-if="accounts.length<7"
          text
          x-small
          color="primary"
          :to="{name: 'add-account', params: { sponsor: item.id }}">
            Add Account
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import Cookies from 'universal-cookie'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import get from '@/composables/get'
import store from '../../store'

export default {
  setup(props) {
    const defaultImage = require('@/assets/images/avatars/3.png')
    const search = ref('')
    const headers = ref([
      { text: '', align: 'start', value: 'img', sortable: false },
      { text: 'Member ID', align: 'start', value: 'registration_code', sortable: false },
      { text: 'Member Name', align: 'start', value: 'name', sortable: false },
      { text: 'Entry', align: 'start', value: 'entry_type', sortable: false },
      { text: 'Date Registered', align: 'start', value: 'created_at', sortable: false },
      { text: 'Level', align: 'start', value: 'level', sortable: false },
      { text: 'Sponsor', align: 'start', value: 'sponsor', sortable: false },
      { text: 'Action', align: 'start', value: 'action', sortable: false },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const level = ref(1)
    const levels = ref([])
    const account = ref('')
    const accounts = ref([])

    const parseAccount = () => {
      const user = store.state.user.info
      
      for(const row of user.member_account) {
        accounts.value.push({value: row.id, text: row.registration_code});
      }

      account.value = user.member_account[0].id
    }

    parseAccount()

    const listUri = `${process.env.VUE_APP_URI}/api/member/network/structure/${account.value}/${level.value}`
    
    watch(() => level.value, val => {
      const listUri = `${process.env.VUE_APP_URI}/api/member/network/structure/${account.value}/${val}`
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(() => account.value, val => {
      const listUri = `${process.env.VUE_APP_URI}/api/member/network/structure/${val}/${level.value}`
      getData(listUri, items, totalItems, loading, options, search)
      getLevels()
    })

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search)
    })

    const getLevels = async () => {
      await get(`${process.env.VUE_APP_URI}/api/member/network/unilevel/${account.value}/levels`, levels, null)
      console.log(levels.value)
    }

    getLevels()

    return {
      defaultImage,
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      accounts,
      account,
      levels,
      level,
    }
  },

  components: {
    snackbar,
  },
}
</script>
