<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      lg="4"
    >
      <v-card :loading="loading" color="info">
        <statistics-card-vertical
          color="success"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(wallet_balance)"
          stat-title="Available Balance"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="4"
      lg="4"
    >
      <v-card :loading="loading" color="warning">
        <statistics-card-vertical
          color="info"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(withdrawals)"
          stat-title="Total Withrawals"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="4"
      lg="4"
    >
      <v-card :loading="loading" color="warning">
        <statistics-card-vertical
          color="success"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(e_savings)"
          stat-title="Total E-Savings"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiTrendingUp, mdiCurrencyUsd, mdiCheck, mdiChartTimelineVariant } from '@mdi/js'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

export default {
  props: {
    e_savings: null,
    wallet_balance: null,
    withdrawals: null,
    loading: Boolean,
  },
  components: {
    StatisticsCardVertical,
  },
  setup() {
    return {
      mdiCurrencyUsd,
    }
  }
}
</script>