<template>
  <v-card class="elevation-1 mt-3">
    <v-card-title>
      Assignment History
      <v-row>
        <v-col
          cols="12"
          md="4"
          lg="4"
          offset-md="8"
          offset-lg="8"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.point_value`]="{ item }">
        {{ Intl.NumberFormat('en-US').format(item.point_value) }}
      </template>
      <template #[`item.total_point_value`]="{ item }">
        {{ Intl.NumberFormat('en-US').format(item.quantity * item.point_value) }}
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'

export default {

  components: {
    snackbar,
  },
  props: ['uniqueID'],
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Date Created', align: 'start', value: 'created_at' },
      { text: 'Recipient', align: 'start', value: 'recipient' },
      { text: 'Recipient ID', align: 'start', value: 'registration_code' },
      { text: 'Product Name', align: 'start', value: 'product_name' },
      { text: 'Point Value', align: 'end', value: 'point_value' },
      { text: 'Quantity', align: 'end', value: 'quantity' },
      { text: 'Total PV', align: 'end', value: 'total_point_value' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)

    const { responseMessageStatus, responseMessage } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/member/maintenance/assignment-history`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(() => props.uniqueID, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      snackbar,
      responseMessageStatus,
      responseMessage,
    }
  },
}
</script>
