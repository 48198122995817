<template>
  <v-col
    cols="12"
    lg="6"
    md="6"
    offset-md="3"
    offset-lg="3"
  >
    <v-card>
      <v-card-title>CPP Genealogy</v-card-title>
      <v-card-text v-show="$store.state.user.info.member_cpp_account.length > 0">
        <v-col
          cols="12"
          md="4"
          lg="4"
        >
          <v-select
            v-model="account"
            :items="accounts"
            label="Account"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-card-text>
      <v-card-text v-if="$store.state.user.info.member_cpp_account.length > 0">
        <v-timeline
          align-top
          dense
        >
          <v-timeline-item
            color="success"
            :icon="icon.mdiNumeric1"
          >
            <v-row class="pt-1">
              <v-col cols="12">
                <v-chip
                    color="success"
                    label
                    v-if="level1"
                  >
                  Completed
                </v-chip>
                <v-chip
                    color="error"
                    label
                    v-else
                  >
                  Incomplete
                </v-chip>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            color="secondary"
            :icon="icon.mdiNumeric2"
          >
            <v-row class="pt-1">
              <v-col cols="12">
                <v-chip
                    color="success"
                    label
                    v-if="level2"
                  >
                  Completed
                </v-chip>
                <v-chip
                    color="error"
                    label
                    v-else
                  >
                  Incomplete
                </v-chip>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            color="primary"
            :icon="icon.mdiNumeric3"
          >
            <v-row class="pt-1">
              <v-col cols="12">
                <v-chip
                    color="success"
                    label
                    v-if="level3"
                  >
                  Completed
                </v-chip>
                <v-chip
                    color="error"
                    label
                    v-else
                  >
                  Incomplete
                </v-chip>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            color="warning"
            :icon="icon.mdiNumeric4"
          >
            <v-row class="pt-1">
              <v-col cols="12">
                <v-chip
                    color="success"
                    label
                    v-if="level4"
                  >
                  Completed
                </v-chip>
                <v-chip
                    color="error"
                    label
                    v-else
                  >
                  Incomplete
                </v-chip>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            color="error"
            :icon="icon.mdiNumeric5"
          >
            <v-row class="pt-1">
              <v-col cols="12">
                <v-chip
                    color="success"
                    label
                    v-if="level5"
                  >
                  Completed
                </v-chip>
                <v-chip
                    color="error"
                    label
                    v-else
                  >
                  Incomplete
                </v-chip>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            color="info"
            :icon="icon.mdiNumeric6"
          >
            <v-row class="pt-1">
              <v-col cols="12">
                <v-chip
                    color="success"
                    label
                    v-if="level6"
                  >
                  Completed
                </v-chip>
                <v-chip
                    color="error"
                    label
                    v-else
                  >
                  Incomplete
                </v-chip>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            color="success"
            :icon="icon.mdiNumeric7"
          >
            <v-row class="pt-1">
              <v-col cols="12">
                <v-chip
                    color="success"
                    label
                    v-if="level7"
                  >
                  Completed
                </v-chip>
                <v-chip
                    color="error"
                    label
                    v-else
                  >
                  Incomplete
                </v-chip>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-text v-else>No Registered Cpp Account </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { 
  mdiNumeric1,
  mdiNumeric2,
  mdiNumeric3,
  mdiNumeric4,
  mdiNumeric5,
  mdiNumeric6,
  mdiNumeric7,
} from '@mdi/js';
import get from '@/composables/get'
import { ref, watch } from '@vue/composition-api';
import store from '../../store'

export default {
  metaInfo: {
    title: 'CPP Genealogy',
  },
  setup() {
    const accounts = ref([])
    const account = ref()
    const genealogy = ref([])
    const level1 = ref(false)
    const level2 = ref(false)
    const level3 = ref(false)
    const level4 = ref(false)
    const level5 = ref(false)
    const level6 = ref(false)
    const level7 = ref(false)

    const getCppAccount = async () => {
      let accts = []
      await get(`${process.env.VUE_APP_URI}/api/member/network/cpp-account`, accounts, null)
      account.value = accounts.value[0]
    }

    const getCppGenealogy = async (val) => {  
      reset()
      await get(`${process.env.VUE_APP_URI}/api/member/network/cpp/${val}`, genealogy, null)
      genealogy.value.forEach(function(val,index) { 
        if(val.level == 1) {
          if(val.completed_at !== '' && val.completed_at !== null) level1.value = true
        } else if(val.level == 2) {
          if(val.completed_at !== '' && val.completed_at !== null) level2.value = true
        } else if(val.level == 3) {
          if(val.completed_at !== '' && val.completed_at !== null) level3.value = true
        } else if(val.level == 4) {
          if(val.completed_at !== '' && val.completed_at !== null) level4.value = true
        } else if(val.level == 5) {
          if(val.completed_at !== '' && val.completed_at !== null) level5.value = true
        } else if(val.level == 6) {
          if(val.completed_at !== '' && val.completed_at !== null) level6.value = true
        } else if(val.level == 7) {
          if(val.completed_at !== '' && val.completed_at !== null) level7.value = true
        }
      })  
    }

    getCppAccount()

    watch(() => account.value, val => {
      getCppGenealogy(val)
    })

    const reset = () => {
      level1.value = false
      level2.value = false
      level3.value = false
      level4.value = false
      level5.value = false
      level6.value = false
      level7.value = false
    }

    return {
      account,
      accounts,
      genealogy,
      level1,
      level2,
      level3,
      level4,
      level5,
      level6,
      level7,
      icon: {
        mdiNumeric1,
        mdiNumeric2,
        mdiNumeric3,
        mdiNumeric4,
        mdiNumeric5,
        mdiNumeric6,
        mdiNumeric7,
      }
    }
  },
}
</script>