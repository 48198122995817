import Cookies from "universal-cookie/es6";
import axios from "axios";

const state = {
    info: [],
    mega_center: false,
    member_account: []
};

const actions = {
  setInfo({commit}, user) {
    commit('info', user);
  },

  async logoutUser() {
    const cookie = new Cookies;
    try {
      await axios.post(process.env.VUE_APP_URI + '/api/auth/member/logout');
      await cookie.remove('access_token', { 
        path    : '/',
        sameSite: true
      });
      await cookie.remove('expires_in', { 
        path    : '/',
        sameSite: true
      });
      window.location.href = "/"
    } catch {

    }
  },

  isLogged() {
    const cookies = new Cookies();
    const accessToken = cookies.get('access_token')

    return accessToken !== undefined
  }
}

const getters = {
  getMemberAccount: state => state.info.member_account,
  getMemberInfo: state => state.info
};

const mutations = {
  info(state, data) {
    state.info = data;
  },

  mega_center(state, data) {
    state.mega_center = data;
  },

  member_account(state, data) {
    state.member_account = data;
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
}