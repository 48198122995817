<template>
  <v-card class="elevation-1">
    <v-card-title>
      Referral Bonus
    </v-card-title>
    <v-card-text class="mb-0 pb-0">
      <v-row>
        <v-col
          cols="12"
          md="4"
          lg="4"
        >
          <v-select
            v-model="account"
            :items="accounts"
            :item-text="'text'"
            :item-key="'id'"
            label="Account"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="4"
          offset-md="4"
          offset-lg="4"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.gross`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.gross) }}</span>
      </template>
      <template #[`item.wtax`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.wtax) }}</span>
      </template>
      <template #[`item.net`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.net) }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import Cookies from 'universal-cookie'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import store from '../../store'

export default {
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Member ID', align: 'start', value: 'registration_code' },
      { text: 'Member Name', align: 'start', value: 'name' },
      { text: 'Entry', align: 'start', value: 'entry_type' },
      { text: 'Date Registered', align: 'start', value: 'created_at' },
      { text: 'Level', align: 'start', value: 'level' },
      { text: 'Gross', align: 'end', value: 'gross' },
      { text: 'WTax', align: 'end', value: 'wtax' },
      { text: 'Net', align: 'end', value: 'net' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const account = ref('')
    const accounts = ref([])

    const listUri = `${process.env.VUE_APP_URI}/api/member/earnings/referrals`

    const parseAccount = () => {
      const user = store.state.user.info
      
      for(const row of user.member_account) {
        accounts.value.push({value: row.id, text: row.registration_code});
      }

      account.value = user.member_account[0].id
      getData(listUri, items, totalItems, loading, options, search, account.value)
    }

    parseAccount()

    watch(account, () => {
      getData(listUri, items, totalItems, loading, options, search, account.value)
    })

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search, account.value)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search, account.value)
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      accounts,
      account,
    }
  },

  components: {
    snackbar,
  },
}
</script>
